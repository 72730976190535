<template>
  <div class="c-team">
    <div class="c-team__group" v-for="(group, index) in teamData" :key="'group' + index">
      <h2 class="c-team__title">{{ group.group }}</h2>
      <div class="c-team__container">
        <div class="c-team__item" v-for="(member, idx) in group.members" :key="idx" @click="showModal(member)">
          <img :src="member.photo" :alt="member.name" class="c-team__photo">
          <div class="c-team__info">
            <h3 class="c-team__name">
              <span class="c-team__name-text">{{ member.name }}</span>
              <div class="c-team__arrow-container">
                <span class="c-team__arrow">></span>
              </div>
            </h3>
            <p class="c-team__role">{{ member.role }}</p>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="isModalVisible" @content="userSelected" @close="closeModal">
      <template v-slot:name>
        <h2 class="c-about-modal__name">{{ userSelected.name }}</h2>
      </template>
      <template v-slot:role>
        <h3>{{ userSelected.role }}</h3>
      </template>
      <template v-slot:content>
        <p class="c-about-modal__content" v-html="userSelected.content"></p>
      </template>
    </modal>
  </div>
</template>
<script>
import $ from 'jquery'
import modal from '@/components/peopleatsparrow/ModalTeam.vue'
export default {
  name: 'TeamList',
  components: {
    modal,
  },
  data() {
    return {
      teamData: [
        {
          group: 'Management Team',
          members: [
            {
              name: 'Kenneth Yeo', role: 'Chief Executive Officer', photo: require('@/assets/images/team/Kenneth Yeo.png'),
              content: 'Kenneth has over 11 years of professional experience as a proprietary trader in derivatives with proficiency in areas covering high frequency trading and futures in regulated market on CME, LME, EUREX, LIFFE, SGX, TSE, HKFE and OSE. As a visionary and driven leader within the cryptocurrency industry, he has led a team of traders specializing in algorithmic and discretionary trading. Formerly one of Singapore’s top professional gamers, Kenneth is also a member of the Singapore Cybersports and Online Gaming Association (SCOGA). As the Chief Executive Officer of Sparrow, Kenneth believes in designing an efficient ecosystem, by integrating data transparency and automation to facilitate transactions and processes, and essentially raising the standards in digital asset wealth management.'
            },
            // {name:'Adrian Tan', role: 'Chief Investment Officer', photo:require('@/assets/images/team/Adrian Tan.png'),
            //   content: 'Adrian started his career as a private wealth management professional at BNP Paribas. He illustrated consistent trading records at the Omega Proprietary Trading in derivatives. With over a decade of experience trading in regulated financial markets including OSE, CME and SGX, Adrian also has trading experience in China, leading a team of traders in Shanghai. As the co-founder of Sparrow and a licensed derivatives trader, Adrian is specialized in solutions covering option and structured product designs, financial pricing model, risk and treasury management and financial advisories. To optimize investments’ deliverables, Adrian is well-versed in trading strategies including discretionary, algorithm-based, arbitrage and high frequencies trading.'
            // },
            {
              name: 'Liu Li', role: 'Chief Technology Officer', photo: require('@/assets/images/team/Liu Li.png'),
              content: 'Liu Li has over 11 years of experience in information technology, cloud infrastructure, data analytics, cybersecurity, and supporting business operations with reliable and secure technology solutions. Prior to joining Sparrow, he has led in various roles responsible for financial and technical planning, business requirement gathering, procurement, service rollout, and vendor management. With presence in leading organizations including Singtel, Liu Li is well versed in IT platforms, cloud computing, web application development, data analytics, and cybersecurity. Some of Liu Li’s key projects and major milestone in Sparrow includes establishing the Sparrow platform roadmap and innovations. He also spearheads the technology design of the platforms, operations and infrastructure. In addition, Liu Li also champions the technology risk management, cybersecurity and data protection.'
            },
            {
              name: 'Toh Ghim', role: 'Chief Operating Officer', photo: require('@/assets/images/team/Toh Ghim.png'),
              content: 'With over 11 years of project management, Toh has led projects across Asia including China, Taiwan, Thailand and the Philippines. As a qualified Project Management Professional (PMP), Toh is well-versed in digitalization and innovation solutions including VR, AR, 3D, Edtech and algorithmic trading. As the Chief Operating Officer of Sparrow, he established agile business processes and ensured robust operational standards. Toh believes in maximizing operational efficiency and effectiveness through team engagement and process innovation. With his background in trading derivatives in the regulated financial markets including SGX, CME and OSE, his project management experience with the military and education sector, including major brands such as Marina Bay Sands and Nanyang Technological University, Toh ensures Sparrow operates at a level that serves institutional needs.'
            },
            // {name:'Reuben Conceicao', role: 'Chief Strategy Officer', photo:require('@/assets/images/team/Reuben Conceicao.png'),
            //   content: 'Reuben has a decade of experience in growing start-ups, corporate training, and corporate communications, helping organizations engage with a rapidly changing world. As Chief Strategy Officer of Sparrow, he plans the long-term strategic direction of the company and drives fund-raising activities. <br/><br/>He previously worked with organizations such as 3M, Orica Mining, BAE Systems, National Healthcare Group, Civil Aviation Authority of Singapore, Ministry of Defence, Ministry of Home Affairs, Singapore Customs, Housing Development Board and Energy Market Authority, providing them with communication strategy solutions and training.<br/><br/>Reuben currently serves as the Vice President of the Singapore Cybersports and Online Gaming Association (SCOGA), and is a member of the Institute of Public Relations of Singapore (IPRS). He possesses a business degree from California State University, Long Beach, double-majoring in Finance (Investments) and International Business, achieving the Most Outstanding Graduate in Finance (2009). He also possesses the WSQ Advanced Certificate in Training and Assessment (ACTA).'
            // },
            {
              name: 'Mak Chung Yee', role: 'Chief Risk & Compliance Officer', photo: require('@/assets/images/team/Mak Chung Yee-2.png'),
              content: 'Chung Yee has extensive experiences in leading the compliance, enterprise risk management and internal auditing functions to organization vary from MNC to public listed companies with Asia Pacific presences across diverse industries. Adding on to her track record, Chung Yee founded the risk consulting business unit of TransFingo Pte Ltd and had guided the unit in expanding the business portfolio to more than 20 public listed clients in the Singapore Stock Exchange. In her last employment with PayPal Pte Ltd, an on-line digital payment service provider, she led the SEA Risk and Compliance team and, was responsible for the Enterprise Risk and Compliance Management Program that meets both PayPal global framework and local regulatory requirements.'
            },
            // {name:'Ang Kok Wee', role: 'Chief Revenue Officer', photo:require('@/assets/images/team/Ang Kok Wee-2.png'),
            //   content: 'With 2 decades of experience in FX derivatives trading, Kok Wee specializes in product structuring and risk management. He is also adept at financial modeling and real estate valuation. Previously with Citigroup, Kok Wee was the head of FX Options Desk, delivering track records in desk profitability and desk expense optimization. In addition, he spearheaded the integration of European, Middle East, and Africa franchises into existing portfolios, and boosted assets under management by 20%. As the Chief Revenue Officer, Kok Wee will oversee the aligned Sales and Trading functions. He aims to synergize the teams to continue to deliver exceptional trade execution standards and client experiences. By constantly enhancing our suite of bespoke digital asset solutions, Kok Wee believes it will help bridge traditional finance and deliver sound portfolio management and diversification solutions to our clients which include institutions, wealth managers and accredited investors.'
            // },
            {
              name: 'Lynn Leong', role: 'Chief Financial Officer', photo: require('@/assets/images/team/Lynn Leong.png'),
              content: 'Lynn has been a financial leader for over 20 years, with regional experience in various finance functions, including Country Controllership, group consolidation, financial reporting and analysis, IFRS, tax, and internal controls. Prior to joining Sparrow, Lynn was the Head of Finance at DBS for business groups including Vickers Group and Capital Markets Group, before stepping up to support DigiExchange and the digital assets ecosystem of the bank. In her earlier years, she headed the finance team with Nikko Asset Management Asia and was also Group Controller for Aman Resorts. At Sparrow, Lynn will spearhead the finance team to ensure a sound financial structure in accordance with Singapore’s Financial Reporting Standards and Financial Regulatory Requirements. In addition, she will oversee the operations of the finance activities including business planning, financial health, policies, and procedures.'
            },
          ]
        },
        {
          group: 'Heads of Department',
          members: [
            // {name:'Joannie Heng', role: 'Financial Controller', photo:require('@/assets/images/team/Joannie Heng.png'),
            //   content: 'Joannie is a CPA (Au) and majored in Accounting and Finance with more than 11 years of professional experience managing a full suite of accounting processes, audit, cash flow management, business budgeting and treasury. She has diverse exposures in various business sectors including education, retail and logistics and has engaged with leading institutions including Deloitte. With her vast background as a finance specialist, Joannie is exposed with dynamic organizations ranging from SME start-up, MNCs and large capacity regional offices. '
            // },
            // {name:'Junie Yeo', role: 'Head of Marketing', photo:require('@/assets/images/team/Junie Yeo.png'),
            //   content: 'With over 21 years of experience specializing in performance marketing, Junie covers areas of interest both locally and regionally including PR and branding, digital marketing, social media and product innovations. Junie has collaborated with major brands and shared insights on scent marketing with Money FM 89.3. Attaining professional education in Master of Science (MSc in Marketing), Junie has also engaged in areas of Mergers and Acquisitions with brand integration in Singapore and Indonesia and has re-branded businesses in Indonesia.'
            // },
            // {name:'Amanda Tan', role: 'Head of Human Resources', photo:require('@/assets/images/team/Amanda.png'),
            //   content: 'Amanda has with her more than 25 years of professional HR experiences in the different pillars of HR including business partnering, talent acquisition, learning & development across a wide array of industries covering F&B, Hospitality, ICT, Logistics & Transportation and Manufacturing.  She drove operational excellence in the delivery of HR services and partnered with key business stakeholders to deliver strategic solutions to meet business needs and achieve organizational business objectives.  Amanda will spearhead the Human Resources function at Sparrow, with a business goal to gear Sparrow towards being the preferred Employer and establish robust HR policies and framework to drive engagement and development within the growing Sparrow family.'
            // },
            {
              name: 'Faith Ng', role: 'Deputy Head of Sales (Singapore)', photo: require('@/assets/images/team/Faith Ng-2.png'),
              content: 'Faith has over 10 years of professional experience as a relationship manager managing quality account management with leading brands including Standard Chartered Bank and Shangri La Hotel Singapore. Engaged in diverse industries including commercial banking and hospitality, she has work exposures in Australia, China and Indonesia. As the Deputy Head of Sales of the Singapore market, Faith is committed to excelling in what she does, and is dedicated to delivering optimal service standards to partners and key stakeholders, while accelerating business development and revenue growth.'
            },
            // {name:'Nicolas Rueda', role: 'Deputy Head of Sales (Latin America)', photo:require('@/assets/images/team/Nicolas Rueda.png'),
            //   content: 'Passionate about the evolvement of FinTech and digital assets, Nicolas brings with him over 20 years of senior executive leadership specializing in asset and wealth management, investment and corporate banking, and capital markets. Nicolas worked with global financial corporations, such as Morgan Stanley and Deutsche Bank, covering multi-family offices, ultra HNWI and business start-ups. As an experienced professional, he offers extensive product knowledge, broad commercial astuteness, and analytical insights. At Sparrow, Nicolas will be spearheading the Latin America region, with strategic executions of digital asset solutions for institutional and HNWI clients.'
            // },
            {
              name: 'Tan Ming Hoe', role: 'Head of Trading', photo: require('@/assets/images/team/Tan Ming Hoe.png'),
              content: 'Ming Hoe has over 13 years of experiences covering areas of FX, equities & commodities trading. Started as a FX Derivatives Dealer with OCBC for 6 years, Ming Hoe then joined numerous family offices to broaden his knowledge of the financial markets while venturing into algorithmic trading. Ming Hoe also accumulated specialization in programming languages including C# and Python. In his academic years, he attained a Merit in MSc Quantitative Finance from CASS Business School London and First-Class Honors in BSc Electronics and Electrical Engineering from University of Edinburgh. As the Head of Trading, Ming Hoe focuses on risk management on Spot and Derivatives flow, ensure optimal execution of trading strategies, and transactions are conducted in accordance with high regulatory compliance.'
            },
          ]
        },
        // {
        //   group: 'Digital Asset Solutions Team',
        //   members:[
        //     {name:'Faith Ng', role: 'Relationship Manager', photo:require('@/assets/images/team/Faith Ng-2.png'),
        //       content: 'Faith has over 11 years of professional experience as a relationship manager managing quality account management with leading brands including Standard Chartered Bank and Shangri La Hotel Singapore. Engaged in diverse industries including commercial banking and hospitality, she has work exposures in Australia, China and Indonesia. Committed to excel in what she does, Faith is dedicated to delivering optimal service standards to partners and key stakeholders, while accelerating business development and revenue growth.'
        //     },
        //     {name:'Jonathan Yu', role: 'Relationship Manager', photo:require('@/assets/images/team/Jonathan Yu.png'),
        //       content: 'With over 11 years of experience in the Financial Services Industry, Jonathan is an experienced account manager, with extensive knowledge in trading and investing. His experience ranges from global brokering houses, venture capital, and in selling mission critical financial technology. Jonathan is dedicated to delivering trusted and reliable services to all his clients.'
        //     },
        //   ]
        // }
      ],
      isModalVisible: false,
      userSelected: null,
    }
  },
  mounted: function () {
    this.initArrows()
  },
  methods: {
    initArrows: function () {
      $(document).ready(function () {
        $('.c-team__name').each(function () {
          let fullWidth = $(this).width();
          let nameWidth = $(this).find('.c-team__name-text').width();
          let arrowWidth = fullWidth - nameWidth - 5;
          $(this).find('.c-team__arrow-container').width(arrowWidth + 'px')
        })
      })
    },
    showModal(dt) {
      this.userSelected = dt
      this.isModalVisible = true;
      $("body").css("overflow-y", "hidden");
    },
    closeModal() {
      this.isModalVisible = false;
      $("body").css("overflow-y", "auto");
    }
  }
}
</script>
